import React, {Component} from "react";
import Layout from "components/Layout/Layout";
import PageHeader from "components/shared/PageHeader";
import StyledButton from "components/shared/StyledButton";
import styled from "styled-components";
import { Container } from "reactstrap";
import {graphql, StaticQuery} from "gatsby"
import {media} from "utils/Media"
import SignUp from "../../components/Footer/SignUp";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import playIcon from "../../images/icons/btn-play.svg";
import ReactPlayer from "react-player";
import GalaBlock from "../../components/GalaPage/GalaBlock"
import VideoBlock from "../../components/GalaPage/VideoBlock";

const Content = styled(Container)`
  position: relative;
  margin: 0 auto;
  text-align: left;
  .uppercase {
    text-transform: uppercase;
  }
  ul {
    list-style: none;
    li {
      max-width: 600px;
      margin: 0.35rem auto;
      font-size: 1.05rem;
    }
  }
  .download {
    @media ${media.md} {
      display: grid;
      margin: 3rem auto;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0rem;
      max-width: 950px;
    }
    @media ${media.lg} {
      grid-gap: 2rem;
      max-width: 885px;
    }
  }
`;

const Border = styled.div`
  background: linear-gradient(
      to bottom,
      ${(props) => props.theme.colors.red},
      ${(props) => props.theme.colors.yellow}
  );
  padding: 3px;
  margin: 0 auto;
`
const CustomBox = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.red},
    ${(props) => props.theme.colors.yellow}
  );
  padding: 3px;
  max-width: 600px;
  margin: 0 auto;
  div {
    background: ${(props) => props.theme.colors.black};
    padding: 1rem;
    position: relative;
    color: ${(props) => props.theme.colors.white};
    h3 {
      font-style: italic;
      font-size: 1.35rem;
      color: ${(props) => props.theme.colors.white};
    }
    p {
      color: ${(props) => props.theme.colors.white};
    }
    a {
      color: ${(props) => props.theme.colors.primary};
    }
  }
`;

class AccessPage extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      playing: false,
    };
  }
  
  onVideoPlay = (e) => {
    this.setState({
      playing: true
    })
  }
  
  render() {
    return (
      <Layout title="Access | London | Back to the Future the Musical" description="Access - The official website for Back to the Future The Musical. Now  playing at the Adelphi Theatre in London.">
        <PageHeader title="Access"
                    imageXs={this.props.data.imageXs}
                    imageSm={this.props.data.imageSm}
                    imageLg={this.props.data.imageLg}
        />
        <Content className="my-3 my-md-5 pb-4 text-center">
          <h3>
            <strong className="uppercase">ACCESS PERFORMANCES</strong>
          </h3>
          <p>
            <strong>
            Relaxed Performance <br />
              <a href="https://ticketing.lwtheatres.co.uk/event/218/performance/55985" target="_blank" rel="noopener ">
              {/* Sunday 10 March 2024{" "} */}
              Sunday 9 March 2025
              </a>
            </strong>
          </p>
  
          <p>
            <strong>
            Signed Performance <br />
              <a href="https://ticketing.lwtheatres.co.uk/event/218/performance/55852" target="_blank" rel="noopener ">
              Sunday 15 September 2024{" "}
              </a>
            </strong>
          </p>
  
          
          
          
          <h3 className="mt-5">
            <strong className="uppercase">Relaxed Performances</strong>
          </h3>
          <p>In March 2023 we welcomed audiences to Hill Valley for our first ever Relaxed Performance. We’re thrilled to say it was a resounding success and we can’t wait for you to join us at the next one.</p>
  
          <p>Please be aware that while we make significant adjustments to the sound and lighting at our Relaxed Performances, there will still be parts of the show that generate 1.21 gigawatts of spectacle.</p>
  
          <p>The show is big, loud and exciting – and may sometimes be challenging – but we want you to enjoy the full, electrifying Back to The Future experience while still feeling comfortable and safe.</p>
  
          <Border>
            <SignUp $access={true} />
          </Border>
          <div className="download">
            <div>
              <p>View and download the <strong>Visual Story</strong> here</p>
              {/* <StyledButton  target="_blank" rel=" noopener" href="/pdfs/education/Back-to-the-Future-Context-Deck.pdf">DOWNLOAD</StyledButton> */}
              <StyledButton  target="_blank" href="/pdfs/london/access/BTTF-visual-story.pdf" className="mb-3">DOWNLOAD</StyledButton>
            </div>
            <div>
              <p>View and download the <strong>Sensory Synopsis</strong> here</p>
              <StyledButton target="_blank" href="/pdfs/london/access/BTTF-sensory-synopsis.pdf"  className="mb-3">DOWNLOAD</StyledButton>
            </div>
          </div>
          
          <div className="video-wrapper my-4">
            <div className={`posterImage ${this.state.playing ? "playing" : ""}`}
                 onClick={this.onVideoPlay}
                 onKeyDown={this.onVideoPlay}
                 tabIndex={0}
                 role="button"
                 aria-label="button"
            >
              <GatsbyImage image={getImage(this.props.data.videoPoster)}
          
                           alt=""
                           title=""
                           className="img-fluid"
              />
              <img src={playIcon} alt="" title="" className="play-icon"/>
            </div>
            <ReactPlayer className="video-frame w-100"
                         url={`https://www.youtube.com/watch?v=2FPp2tg4CZs?rel=0&amp;autoplay=0&modestbranding=1`}
                         playing={this.state.playing}
                         controls={true}/>
          </div>
          <GalaBlock london/>
          <VideoBlock/>
      
          {/*<CustomBox>*/}
          {/*  <div>*/}
          {/*    <h3 className="mt-6">*/}
          {/*      MORE ACCESSIBLE PERFORMANCES*/}
          {/*    </h3>*/}
          {/*    <p>*/}
          {/*      <strong>*/}
          {/*        Captioned Performance <br />*/}
          {/*        <a href="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/performance/1ad1ba74-f99a-4f10-ae1c-d7ee8dbb69da?externalId=1669027857822.64714366346&sineFbp=fb.1.1661159099826.1490032607&_ga=2.45193636.1077532095.1670322885-414700929.1661159099" target="_blank" rel="noopener ">*/}
          {/*          Sunday 14 May 2023{" "}*/}
          {/*        </a>*/}
          {/*      </strong>*/}
          {/*    </p>*/}
          {/*    <p>*/}
          {/*      <strong>*/}
          {/*        Signed Performance <br />*/}
          {/*        <a href="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/performance/d7120317-1e5a-418f-ad06-a9d2de0c0291?externalId=1682514456693.391536458081&sineFbp=fb.1.1651830763246.1699035562&_ga=2.69008145.1094192635.1683283758-709726111.1651830763" target="_blank" rel="noopener ">*/}
          {/*          Sunday 10 September 2023{" "}*/}
          {/*        </a>*/}
          {/*      </strong>*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</CustomBox>*/}
        </Content>
      </Layout>
    )
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
            query {
              videoPoster: file(relativePath: { eq: "BTTF_MAY23_ThumnailAccessPerformances_Vis1a.jpg" }) {
                    childImageSharp {
                             gatsbyImageData(width: 1200)
                    }
                }
                imageXs: file(relativePath: { eq: "576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 575,
                            quality: 100
                        )
                    }
                }
                imageSm: file(relativePath: { eq: "991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(
                            width: 991,
                            quality: 100
                        )
                    }
                }
                imageLg: file(relativePath: { eq: "1920.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(
                            width: 1740,
                            quality: 100
                        )
                    }
                }
            }
		`}
    render={data => (
      <AccessPage
        {...props}
        data={data}/>
    )}
  />
)
