import React, {Component} from 'react';
import styled from "styled-components";
import StyledButton from "../../components/shared/StyledButton"
import DeloreanAnimation from "../Animations/DeloreanAnimation"
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {graphql, StaticQuery} from "gatsby";

const Wrapper = styled.div`
  position: relative;
  text-align: center;

  .title-wrapper {
    width: 90%;
    margin: 0px auto;
    max-width: 1350px;
  }
`
const Content = styled.div`
  margin: 0 auto;
  max-width: 900px;
  padding: 0 1rem;
`

const ImageWrapper = styled.div`
  margin: 2rem auto 0;
  display: flex;
  justify-content: space-between;
  width: 400px;
  max-width: 100%;
  flex-wrap: wrap;
  text-align: center;
  
  .images {
    width: 100px;
    display: block;
    max-width: 100%;
    height: auto;
  }
`

class GalaBlock extends Component {
  render() {
    return (
      <Wrapper className="mt-3 mt-md-5">
          <>
            {/*<div className="title-wrapper">*/}
            {/*  <h2 className="h1 mb-0">WINNER OF BEST NEW MUSICAL <br/>TIME AND TIME AGAIN…</h2>*/}
            {/*</div>*/}
            {/*<p>WhatsOnStage Awards 2022 | Olivier Awards 2022 | Broadway World Awards 2022</p>*/}
            <Content className="my-3 my-md-5">
              
              <h3 className="mt-5">
                <strong className="uppercase">Gala Pro</strong>
              </h3>
              <p>We’ve partnered with GalaPro so you can enjoy audio description and closed captioning at every performance of Back to the Future The Musical!</p>
  
              <p>This revolutionary, patent-pending, app delivers individual multilingual subtitles, closed captioning, dubbing, audio description, and amplification to your own mobile device.</p>
  
              <p>All content is provided in real time, using speech recognition technology, developed specifically for live performances and shows.</p>
  
              <p>Just download the Gala Pro app for free and enjoy some serious... entertainment!</p>
  
              <StyledButton className="my-4" href='https://www.galapro.com/' rel=" noopener" target="_blank">
                DOWNLOAD NOW
              </StyledButton>
              
              <ImageWrapper>
                <div className="images cc">
                  <GatsbyImage image={getImage(this.props.data.cc)}
                               alt=""
                               title=""
                               className="img-fluid"
                  />
                </div>
                <div className="images gala">
                  <GatsbyImage image={getImage(this.props.data.gala)}
                               alt=""
                               title=""
                               className="img-fluid"
                  />
                </div>
                <div className="images ad">
                  <GatsbyImage image={getImage(this.props.data.ad)}
                               alt=""
                               title=""
                               className="img-fluid"
                  />
                </div>
              </ImageWrapper>
            </Content>
          </>
      </Wrapper>
    );
  }
}

export default (props) => (
  <StaticQuery
    query={graphql`
            query {
                cc: file(relativePath: { eq: "GalaPage/508177876-2-1.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 200)
                    }
                }
                ad: file(relativePath: { eq: "GalaPage/508177880-3-1.png" }) {
                    childImageSharp {
                       gatsbyImageData(width: 200)
                    }
                }
                gala: file(relativePath: { eq: "GalaPage/508177883-1-1.png" }) {
                    childImageSharp {
                       gatsbyImageData(width: 200)
                    }
                }
            }
		`}
    render={data => (
      <GalaBlock data={data}/>
    )}
  />
)

