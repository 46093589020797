import React, {Component} from 'react';
import {Container} from "reactstrap"
import styled from "styled-components";
import {graphql, StaticQuery} from "gatsby";
import {media} from "utils/Media"
import ReactPlayer from "react-player"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import StyledButton from "../../components/shared/StyledButton"
import HeaderShape from "../../images/HomePage/header-shape.svg"
import playIcon from '../../images/icons/btn-play.svg'

const Content = styled.div`
    position: relative;
    text-align: center;
    
    @media ${media.lg} {
        margin: 2rem auto 0;
    }
`

const ColouredShape = styled.div`
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media ${media.md} {
        background: url("${HeaderShape}") center center no-repeat;
        background-size: 100%;
        height: 100px;
    }
    
    h3 {
        text-align: center;
        width: 100%;
        
        @media ${media.md} {
            width: 500px;
            font-size: 1.2rem;
        }
        
        @media ${media.lg} {
             width: 100%;
             max-width: 680px;
             font-size: 1.85rem;
         }
    }
    .font-override {
        @media ${media.lg} {
             width: 100%;
             max-width: 680px;
             font-size: 1.5rem;
         }
    }
`

class GreatScottBlock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            playing: false,
        };
    }

    onVideoPlay = (e) => {
        this.setState({
            playing: true
        })
    }
    render() {
        console.log(this.props)
        return (
            <Content>
                <Container className="py-3 py-lg-5">
                    <ColouredShape>
                        <h3 className="mb-3 mb-md-0 pt-3 pt-xl-0">
                            WATCH GALA PRO IN ACTION
                        </h3>
                    </ColouredShape>
                    <div className="video-wrapper my-2">
                        <div className={`posterImage ${this.state.playing ? "playing" : ""}`}
                             onClick={this.onVideoPlay}
                             onKeyDown={this.onVideoPlay}
                             tabIndex={0} 
                             role="button"
                             aria-label="button"
                        >
                            <GatsbyImage image={getImage(this.props.data.posterImage)}

                                alt=""
                                title=""
                                className="img-fluid"
                            />
                            <img src={playIcon} alt="" title="" className="play-icon"/>
                        </div>
                        <ReactPlayer className="video-frame w-100"
                                     url={`https://www.youtube.com/watch?v=43wEn_mQAg8?rel=0&amp;autoplay=0&modestbranding=1`}
                                     playing={this.state.playing}
                                     controls={true}/>
                    </div>
                </Container>
            </Content>

        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                posterImage: file(relativePath: { eq: "AboutPage/first_frame.jpg" }) {
                    childImageSharp {
                             gatsbyImageData(width: 1200)
                    }
                }
            }
		`}
        render={data => (
            <GreatScottBlock data={data} newyork={props.newyork}/>
        )}
    />
)
